import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, message } from 'antd';
import * as _ from 'lodash';
import { Row, Col } from 'antd';
import { loginUser } from '../../helpers/firebase_auth';
import LogoImage from '../../assets/images/logo.png';
import { Routes } from '../../routes/route_helper';
import { appOTP, appConfirmOTP, appUserRegister } from '../../helpers/firebase_functions';
import { OtpType } from '../../helpers/types';
const Login = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [username, setUsername] = useState('');
  const [otp, setOTP] = useState('');
  const [openOTP, setOpenOTP] = useState(false);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async () => {
    if (!validate()) return;
    try {
      setLoading(true);
      const res = await appOTP(username, OtpType.APP_LOGIN);
      console.log(res)
      if (res.status || res.msg == "Баталгаажуулах код илгээсэн байна.") {
        setOpenOTP(true)
        setLoading(false)
      } else {
        setLoading(false)
        messageApi.error(res.msg)
      }
      // navigate(Routes.dashboard);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  };
  const onSubmitOTP = async () => {
    if (_.isEmpty(username)) return;

    const payload = { phone: username, type: OtpType.APP_LOGIN, otp_code: parseInt(otp) }
    try {
      setLoading(true);
      console.log(payload)
      const res = await appConfirmOTP(payload);
      console.log(res)
      if (res.status) {
        await loginUser(res.data.token);
        navigate(Routes.dashboard);
      } else {
        setLoading(false)
        messageApi.error(res.msg)
      }
      // navigate(Routes.dashboard);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  };

  const validate = () => {
    if (_.isEmpty(username)) return false;
    return true;
  };

  return (
    <div className='flex items-center justify-center' >
      {contextHolder}
      <div className='container' >
        <div className="flex items-center justify-center h-full">
          <div className="card-container">
            <div className="border rounded-md border-gray-600 w-full sm:w-auto">

              <div className="dialog-body">
                <div className="mt-4 flex justify-center"  >
                  <img alt="logo" src={LogoImage} height={333} width="auto" style={{ margin: 10 }} />
                </div>
                <div className="dialog-content px-1">
                  <Input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Утасны дугаар"
                    className="mb-1"
                  />
                  {openOTP && (
                    <Input
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                      placeholder="SMS код"
                      className="mb-1"
                  
                    />
                  )}

                  <div className="error-text text-sm pt-1 text-error">{error}</div>
                </div>
              </div>
              <div className="dialog-footer rounded-b-md">
              {openOTP && (
                <Button
                className="w-full rounded-xl mx-2"
                type='primary'
                onClick={onSubmitOTP}
                loading={loading}
              >
                Баталгаажуулах
              </Button>
              )}
              {!openOTP && (
                <Button
                  className="w-full rounded-xl mx-2"
                  type='primary'
                  onClick={onSubmit}
                  loading={loading}
                >
                  Үргэжлүүлэх
                </Button>
              )}


              </div>

            </div>

          </div>

        </div>


      </div>

    </div>

  );
};

export default Login;
